export const environment = {
  production: false,
  localBackend: false,
  firebaseConfig: {
    apiKey: 'AIzaSyDUnsRNnkkxQyOi4kpq7R4rCfi5RvHEK0k',
    authDomain: 'buki-fb-pjkt2.firebaseapp.com',
    databaseURL: 'https://buki-fb-pjkt2-default-rtdb.firebaseio.com',
    projectId: 'buki-fb-pjkt2',
    storageBucket: 'buki-fb-pjkt2.appspot.com',
    messagingSenderId: '2762678991',
    appId: '1:2762678991:web:325714a332e7c22c490dba',
    measurementId: 'G-FD3BQNMVQW',
  },
};
